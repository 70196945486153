<template>
    <div class="business-page">
        <div style="min-height:1000px;padding-top:100px">
           <a-breadcrumb  style="text-align:left;margin:20px auto 20px;width:70%;">
              <a-breadcrumb-item> <router-link :to="`/`">首页</router-link></a-breadcrumb-item>
              <a-breadcrumb-item><router-link :to="`/Business`">业务介绍</router-link></a-breadcrumb-item>
              <a-breadcrumb-item style="color:rgba(0, 0, 0, 0.45)" >{{caseType==1?'产品详情':'案例详情'}}</a-breadcrumb-item>
            </a-breadcrumb>
            <h3 style="font-size:24px;margin-bottom:30px;">{{name}}</h3>
            <div class="images" v-viewer >
                 <img v-for="(item,index) in content" :key="index" style="margin-bottom:5px;width:70%;cursor:pointer;" :src="item" alt="郑大智能"  >
            </div>
            <router-link class="more" to="/Business">产品列表</router-link>
         </div>
    </div>
</template>
<script>
import  { newsImg } from '@/config/appSetting'
import { getProductCasePage } from '@/api/data'
export default {
    name:'Banner',
    components: {
    },
    data() {
      let that=this
      return  {
        wHeight:0,
        len:0,
        content:[],
        newsImg,
        name:'',
        caseType:null
      }
    },
    created() {
    },
    methods: { },
    mounted() {
        let that=this
        getProductCasePage({},this.$route.params.id).then(res => {
            if (res.state) {
                if(res.data&&res.data.length>0){
                    res.data.map((item)=>{
                        that.name=item.productName
                        that.caseType=item.productType
                        if(item.productCaseImages&&item.productCaseImages.length>0){
                            item.productCaseImages.map((num)=>{
                                if(num&&that.caseType==1&&num.imageSortMark>1){
                                    let imgurl=num.imageUrl?that.newsImg+num.imageUrl:''
                                    that.content.push(imgurl)
                                }
                                if(num&&that.caseType==2&&num.imageSortMark>0){
                                    let imgurl=num.imageUrl?that.newsImg+num.imageUrl:''
                                    that.content.push(imgurl)
                                }
                            })
                        }
                    })
                }
            }
        })
    }
}
</script>
<style lang="less" scoped>
  .more{
        display: block;
        width: 166px;
        height: 40px;
        border: solid 1px #999;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        color: #666;
        margin:30px auto;
    }
</style>
<style>
.viewer-container.viewer-backdrop.viewer-fixed.viewer-fade.viewer-transition.viewer-in{
  background-color: rgba(255, 255, 255, 0.85)!important;
}
</style>


